<template>
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div
        aria-live="assertive"
        class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-[75]">
        <div
            class="flex w-full flex-col items-center space-y-4 sm:items-end"
            :class="position === 'bottom-right' ? 'place-self-end' : null">
            <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
            <TransitionGroup
                enter-active-class="transform ease-out duration-300 transition"
                enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <NotificationBanner
                    v-for="notification in notifications"
                    :key="notification.id"
                    :notification="notification"
                    @dismiss="$event => removeNotification($event.id)" />
            </TransitionGroup>
        </div>
    </div>
</template>

<script setup lang="ts">

const props = withDefaults(defineProps<{
    position: 'top-right' | 'bottom-right'
}>(), {
    position: 'bottom-right'
})

const {removeNotification, notifications} = useNotification();
</script>